import { Link } from "gatsby"
import React, { useState } from "react"
import defaultNavigation from "../content/navigation"

/**
 *
 * @param {{navigation: Array<{to: string, text: string, isButton?: boolean}>}} param0
 * @returns
 */

const Header = ({ navigation = defaultNavigation }) => {
  const [isExpanded, setExpansion] = useState(false)
  const logoScaleFactor = 150 / 300
  const year = new Date().getFullYear()

  return (
    <div className="header-wrapper has-background-dark">
      <nav
        className="navbar container py-4 px-4 has-background-dark"
        role="navigation"
        aria-label="main navigation"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
        }}
      >
        <Link
          to="/"
          className="logo"
          style={{
            height: logoScaleFactor * 61,
            width: logoScaleFactor * 300,
          }}
        ></Link>

        <nav
          className="navbar is-hidden-touch has-background-dark"
          role="navigation"
          aria-label="dropdown navigation"
        >
          {navigation.map(({ to, text, isButton }) => {
            if (isButton) {
              return (
                <div className="navbar-item" key={to}>
                  <Link to={to} className="button is-rounded is-link">
                    {text}
                  </Link>
                </div>
              )
            }
            return (
              <Link to={to} key={to} className="navbar-item has-text-white">
                {text}
              </Link>
            )
          })}
        </nav>

        <div
          className="is-flex is-hidden-desktop"
          style={{ alignItems: "center" }}
        >
          <a
            role="button"
            className={`navbar-burger 
               "has-text-white"
             ${isExpanded ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded={isExpanded}
            onClick={() => setExpansion(!isExpanded)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        {isExpanded && (
          <div className="mobile-overlay-menu has-background-dark">
            <nav className="mobile-overlay-menu--nav py-3 px-4">
              {navigation.map(({ to, text }) => (
                <Link to={to} key={to} className="has-text-white py-1">
                  {text}
                </Link>
              ))}
            </nav>
            <div className="mobile-overlay-menu--footer py-4">
              <div className="op-6 is-size-7 has-text-white">
                © Copyright {year} inPowered
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  )
}

export default Header
